import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// cached data
import stats from "./stats.json";
import bears from "./allbears.json";
import bearToMint from "./bearToMintIndex.json";
import bearToRanking from "./bearToRanking.json";
import allcats from "./cats/allcats.json";
import catsRarity from "./cats/cats_rarity.json";

const cats = Object.keys(allcats).map((mint) => {
  const info = allcats[mint];
  info["mint"] = mint;
  return info;
});

const address = "Dis4od3nJasYBQPc3MHdXd7Mcxeocm3CVHg9ALAETncr";

const IPFS_IMAGE_PREFIX = "https://cloudflare-ipfs.com/ipfs/";

const PILL_BG = {
  Background: "bg-purple-300",
  Bear: "bg-yellow-300",
  Fur: "bg-yellow-300",
  Clothes: "bg-green-300",
  Body: "bg-green-300",
  Eyes: "bg-gray-300",
  Hat: "bg-blue-300",
  Head: "bg-blue-300",
  Mouth: "bg-red-300",
  Face: "bg-red-300",
  Direction: "bg-indigo-300",
};
const PILL_TEXT = {
  Background: "text-purple-700",
  Bear: "text-yellow-700",
  Fur: "text-yellow-700",
  Clothes: "text-green-700",
  Body: "text-green-700",
  Eyes: "text-gray-700",
  Hat: "text-blue-700",
  Head: "text-blue-700",
  Mouth: "text-red-700",
  Face: "text-red-700",
  Direction: "bg-indigo-700",
};

function getRarity(trait, animal) {
  const total = animal === "bear" ? 10000 : 6320;
  const type = String(trait["trait_type"]).toLowerCase();
  const value = String(trait["value"]).toLowerCase();
  const rarity = animal === "bear" ? stats : catsRarity;
  if (rarity[type] && rarity[type][value]) {
    const count = rarity[type][value];
    const percentage = (count * 100) / total;
    return percentage.toPrecision(4);
  } else {
    return "???";
  }
}

function getBearData(id) {
  const index = Number(id) - 1;
  const metadata = bears[index];
  const bear = {
    index: index,
    name: metadata[0],
    image: `${IPFS_IMAGE_PREFIX}${metadata[1]}`,
    attributes: metadata[2],
  };
  return bear;
}

function getCatData(id) {
  const name = "SolCat #" + id;
  const findCat = cats.filter((cat) => cat["name"] === name);
  if (findCat.length < 1) {
    return null;
  }
  return {
    index: id - 1,
    name: name,
    image: findCat[0].image,
    attributes: findCat[0].attributes,
  };
}

function getBearMintAddress(index) {
  return bearToMint[index];
}

function getBearRanking(bearIndex) {
  if (bearToRanking[bearIndex]) {
    return bearToRanking[bearIndex];
  }
  return null;
}

// function getShareLink(bearIndex) {
//   return window.location;
// }

// async function fetchWithTimeout(resource, options = {}) {
//   const { timeout = 4000 } = options;

//   const controller = new AbortController();
//   const id = setTimeout(() => controller.abort(), timeout);

//   const response = await fetch(resource, {
//     ...options,
//     signal: controller.signal,
//     mode: "cors",
//   });
//   clearTimeout(id);

//   return response;
// }

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Bear() {
  const [input, setInput] = useState("");
  const [data, setData] = useState(null);
  const query = useQuery();
  const history = useHistory();

  const bearNum = query.get("bear");
  const catNum = query.get("cat");
  const animal = bearNum ? "bear" : "cat";
  const [type, setType] = useState(animal);

  useEffect(() => {
    if (bearNum && bearNum > 0 && bearNum < 10001) {
      setData(null);
      getBearMetadata(bearNum);
      // setType("bear");
    } else if (catNum && catNum > 0 && catNum < 10001) {
      setData(null);
      getCatMetadata(catNum);
      // setType("cat");
    }
  }, [bearNum, catNum]);

  useEffect(() => {
    // if ((bearNum && type === "cat") || (catNum && type === "bear")) {
    //   setData(null);
    //   setInput("");
    // }
  }, [type]);

  const getBearMetadata = async (id) => {
    if (id === null) {
      return;
    }
    if (id < 1 || id > 10000) {
      return null;
    }
    const data = getBearData(id);
    setData(data);
  };

  const getCatMetadata = async (id) => {
    if (id === null) {
      return;
    }
    if (id < 1 || id > 6320) {
      return null;
    }
    const data = getCatData(id);
    setData(data);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const search = `?${type}=${input}`;
    history.push({
      pathname: "/",
      search: search,
    });
  };

  return (
    <div className="container mx-auto text-white flex items-center justify-center min-h-screen">
      <div className="w-96">
        <h1 className="mt-4 text-center font-bold text-2xl">
          <a href="/">Unofficial Soltopia Explorer</a>
        </h1>
        {data && (
          <div className="flex flex-col items-center text-white">
            <p className="text-3xl font-black mt-6 flex items-center">
              <span>{data.name}</span>
              <span className="ml-2">
                <button
                  className="px-2 -mr-4"
                  onClick={() => {
                    if (window.navigator && window.navigator.clipboard) {
                      window.navigator.clipboard.writeText(
                        window.location?.href
                      );
                      toast.dark("🧸 Link Copied!", {
                        position: "bottom-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                  }}
                >
                  <Share />
                </button>
              </span>
            </p>
            <img
              src={data.image}
              className="mt-2 w-64 border-4 border-white"
              alt={data.name}
            />
            {animal === "bear" && getBearRanking(data.index) && (
              <span className="mt-3 font-extrabold text-xl">
                Rank: {getBearRanking(data.index)}
              </span>
            )}
            <div className="mt-4">
              {data.attributes.map((x) => {
                return (
                  <div
                    key={x["trait_type"]}
                    className={`${PILL_BG[x["trait_type"]]} ${
                      PILL_TEXT[x["trait_type"]]
                    }
            rounded-full px-6 py-3 mb-2 font-semibold
            `}
                  >
                    {x["trait_type"]}: {x["value"]} (
                    {getRarity(x, bearNum ? "bear" : "cat")}%)
                  </div>
                );
              })}
            </div>
            {animal === "bear" && getBearMintAddress(data.index) && (
              <div className="mt-4">
                <a
                  target="blank"
                  rel="noreferrer"
                  className="flex items-center text-blue-500 underline font-semibold"
                  href={`https://digitaleyes.market/item/${getBearMintAddress(
                    data.index
                  )}`}
                >
                  <span className="mr-2">
                    <Verified />
                  </span>
                  View on DigitalEyes
                </a>
              </div>
            )}
          </div>
        )}
        <form className="w-full mt-6 flex flex-col" onSubmit={onSubmitHandler}>
          <div className=" text-white flex rounded-lg mb-6 cursor-pointer">
            <span
              onClick={() => setType("bear")}
              className={`w-full text-center py-3 text-xl font-bold border-l-2 border-t-2 border-b-2 rounded-l-lg 
              ${
                type === "bear"
                  ? " bg-indigo-500 border-indigo-500 z-10"
                  : " border-white hover:text-indigo-500"
              } `}
            >
              Bear
            </span>
            <span
              onClick={() => setType("cat")}
              className={`w-full text-center py-3 text-xl font-bold border-r-2 border-b-2 border-t-2  rounded-r-lg 
               ${
                 type === "cat"
                   ? " bg-indigo-500 border-indigo-500 z-10"
                   : " border-white hover:text-indigo-500"
               } `}
            >
              Cat
            </span>
          </div>
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            type="number"
            min={1}
            max={type === "bear" ? 10000 : 6320}
            placeholder={`Enter ${type === "bear" ? "SolBear" : "SolCat"} #`}
            className="py-3 mb-2 rounded-lg  px-3  text-xl text-white bg-black border border-white font-medium"
          />
          <button className="bg-white rounded-lg px-3 py-3 mb-6 text-black font-black text-xl">
            Check {type === "bear" ? "SolBear" : "SolCat"}
          </button>
        </form>
        <div className="bg-gray-900 text-lg px-3 py-2 mb-4">
          <span className="text-white font-bold text-md">LINKS</span>
          <ul>
            <li className="mt-4 underline">
              <a href="/gallery/bears">View Bears Gallery</a>
            </li>
            <li className="mt-4 underline">
              <a href="/gallery/cats">View Cats Gallery</a>
            </li>
            <li className="mt-4 underline">
              <a href="/traits">Check Bear Traits</a>
            </li>
            <li className="mt-4">
              {data && (
                <p className="font-medium text=sm mb-2 break-all">
                  If you liked this site, send a bear to {address} 😁🍺
                </p>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const Verified = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

const Share = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

export default Bear;
