import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Bear from "./Bear";
import BearsGallery from "./BearsGallery";
import CatsGallery from "./CatsGallery";
import Traits from "./Traits";

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route path="/gallery/bears">
          <BearsGallery />
        </Route>
        <Route path="/gallery/cats">
          <CatsGallery />
        </Route>
        <Route path="/traits">
          <Traits />
        </Route>
        <Route path="/">
          <Bear />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
