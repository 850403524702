import React, { useState, useEffect } from "react";
import bears from "./allbears.json";
import bearToRanking from "./bearToRanking.json";
import stats from "./stats.json";

const TraitTypes = ["Background", "Bear", "Clothes", "Eyes", "Hat", "Mouth"];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

function Traits() {
  const [showBears, setShowBears] = useState([]);
  const [totalFound, setTotalFound] = useState(0);
  const [filters, setFilters] = useState(() => {
    return TraitTypes.reduce((map, key) => {
      map[key] = "";
      return map;
    }, {});
  });

  const changeFilters = (ttype, tvalue) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[ttype] = tvalue;
    setFilters(newFilters);
  };

  useEffect(() => {
    let newbears = Object.values(bears);
    TraitTypes.map((ttype) => {
      if (filters[ttype] !== "" && filters[ttype] !== "any") {
        newbears = newbears.filter((bear) => {
          const traits = bear[2];
          const lookup = traits.filter(
            (trait) => trait["trait_type"] === ttype
          );
          if (lookup.length === 0) {
            return false;
          }
          if (lookup.length > 0) {
            return (
              lookup[0]["value"].toLowerCase() === filters[ttype].toLowerCase()
            );
          }
          return true;
        });
      }
      return null;
    });
    const randomBunch = newbears.length > 6 ? getRandom(newbears, 6) : newbears;
    setTotalFound(newbears.length);
    setShowBears(randomBunch);
  }, [filters]);

  return (
    <div>
      <h1 className="text-white mt-4 text-center font-bold text-2xl">
        <a href="/">Unofficial SolBears Explorer</a>
      </h1>
      <div className="w-full mt-12 flex justify-center text-white text-4xl font-black traacking-wider">
        <p>Bear Traits</p>
        {/* <p>{JSON.stringify(filters)}</p> */}
      </div>
      <div className="text-white grid grid-cols-1 md:grid-cols-2 gap-6 px-12 mb-12 pt-12">
        <div className="w-full flex flex-col items-center">
          {Object.keys(filters).map((ttype) => {
            return (
              <div
                key={ttype}
                className="w-full mb-6 flex flex-col justify-end items-center text-white text-2xl font-semibold pr-12"
              >
                <p className="mb-2">{ttype}</p>
                <select
                  value={filters[ttype] || "any"}
                  onChange={(e) => {
                    changeFilters(ttype, e.target.value);
                  }}
                  className="bg-black text-white border border-white text-lg px-2 py-2 ml-2 rounded-lg focus:outline-none"
                >
                  <option value="any">Any</option>
                  {Object.keys(stats[ttype.toLowerCase()]).map((tvalue) => (
                    <option key={tvalue} value={tvalue}>
                      {capitalizeFirstLetter(tvalue)}
                    </option>
                  ))}
                </select>
              </div>
            );
          })}
        </div>
        <div className="w-full flex flex-col items-center justify-center text-white text-2xl font-black traacking-wider">
          <p>Sample Bears</p>
          {showBears && showBears.length > 0 && (
            <p className="text-lg">Total: {totalFound}</p>
          )}
          {totalFound === 0 && <p className="text-lg mt-4">No bears found</p>}
          <div className="grid grid-cols-2 gap-4">
            {showBears.map((bear) => {
              return (
                <div className="cursor-pointer" key={bear[0]}>
                  <a
                    target="_black"
                    rel="noreferrer"
                    href={`/?bear=${bear[0].split("#")[1].trim()}`}
                    className="flex flex-col h-full justify-between items-center"
                  >
                    <p className="text-xl font-black mt-6 text-center">
                      <span>{bear[0]}</span>
                    </p>
                    <div>
                      <img
                        placeholder="https://getuikit.com/docs/images/placeholder_600x400.svg"
                        className="mt-2 w-32  border-4 border-white"
                        src={`https://cloudflare-ipfs.com/ipfs/${bear[1]}`}
                        alt={bear[0]}
                      />
                      <p className="text-xs font-black mt-2 text-center">
                        <span>
                          Rank:{" "}
                          {bearToRanking[Number(bear[0].split("#")[1]) - 1]}
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
          <div className="pt-12">
            <p className="text-xl ">Rarity Stats</p>
            <ul className="text-lg mb-12 font-semibold">
              {Object.keys(filters).map((ttype) => {
                if (filters[ttype] !== "" && filters[ttype] !== "any") {
                  return (
                    <li key={ttype} className="list-disc">
                      {ttype}: {capitalizeFirstLetter(filters[ttype])}(
                      {(
                        stats[ttype.toLowerCase()][filters[ttype]] / 1000
                      ).toPrecision(4)}
                      %)
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Traits;
