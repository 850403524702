import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import allcats from "./cats/allcats.json";
// import bearToRanking from "./bearToRanking.json";

const catsArray = Object.values(allcats).sort((left, right) => {
  const l = Number(left.name.split("#")[1]);
  const r = Number(right.name.split("#")[1]);
  return l - r;
});

function getPaginatedData(data, page, perPage) {
  const startIndex = page * perPage - perPage;
  const endIndex = startIndex + perPage;
  return data.slice(startIndex, endIndex);
}

function getPagesToRender(page, totalPages) {
  const leftLimit = 3;
  const leftMax = 6;
  let left = Array.from({ length: leftLimit }, (_, i) => i + 1);
  if (page > leftLimit && page < leftMax) {
    const rem = Array.from(
      { length: page - leftLimit },
      (_, i) => i + leftLimit + 1
    );
    left = left.concat(rem);
  }
  if (page >= leftMax && page <= totalPages - leftMax) {
    left.push("..");
    left = left.concat([page - 1, page, page + 1]);
  }
  left.push("...");
  if (page < totalPages - leftLimit + 1 && page > totalPages - leftMax) {
    const rem = Array.from(
      { length: totalPages - leftLimit + 1 - page },
      (_, i) => totalPages - leftLimit - i
    );
    left = left.concat(rem.reverse());
  }
  const right = Array.from(
    { length: leftLimit },
    (_, i) => i + (totalPages - leftLimit + 1)
  );
  return left.concat(right);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function debounce(func, timeout = 1000) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function Gallery() {
  const query = useQuery();
  const pageQuery = Number(query.get("page") || 1);
  const [page, setPage] = useState(pageQuery);
  const [currPageData, setCurrPageData] = useState([]);
  const [pagesToRender, setPagesToRender] = useState([]);
  const [sortBy, setSortBy] = useState("id");

  const perPage = 40;
  const totalPages = 6320 / perPage;

  const history = useHistory();

  useEffect(() => {
    // const newbears = Object.values(bears);
    // if (sortBy === "id") {
    // setBearsData(newbears);
    // } else {
    // const nn = newbears.slice().sort((left, right) => {
    //   const bearIndexLeft = Number(left[0].split("#")[1]) - 1;
    //   const bearIndexRight = Number(right[0].split("#")[1]) - 1;
    //   return bearToRanking[bearIndexLeft] - bearToRanking[bearIndexRight];
    // });
    // setBearsData(nn);
    // }
  }, [sortBy]);

  useEffect(() => {
    const data = getPaginatedData(catsArray, pageQuery, perPage);
    const paginationData = getPagesToRender(pageQuery, totalPages);
    setCurrPageData(data);
    setPagesToRender(paginationData);
  }, [pageQuery, totalPages]);

  useEffect(() => {
    history.push({
      pathname: "/gallery/cats",
      search: "?page=" + page,
    });
  }, [page, history]);

  function goToNextPage() {
    if (page + 1 <= totalPages) {
      setPage(page + 1);
    }
  }

  function goToPreviousPage() {
    if (page - 1 >= 1) {
      setPage(page - 1);
    }
  }

  function changePage(newPage) {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  }

  return (
    <div>
      <h1 className="text-white mt-4 text-center font-bold text-2xl">
        <a href="/">Unofficial Soltopia Explorer</a>
      </h1>
      <div className="w-full mt-12 flex justify-center text-white text-4xl font-black traacking-wider">
        <p>SolCats Gallery</p>
      </div>
      <div className="w-full mt-3 flex justify-end items-center text-white text-2xl font-semibold pr-12">
        <p>Sort By </p>
        <select
          className="bg-black text-white border border-white text-lg px-2 py-2 ml-2 rounded-lg focus:outline-none"
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="id">Cat #</option>
          {/* <option value="ranking">Ranking</option> */}
        </select>
      </div>
      <div className="text-white grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 px-12 mb-12">
        {currPageData.map((bear) => {
          return (
            <div className="cursor-pointer" key={bear.name}>
              <a
                target="_black"
                rel="noreferrer"
                href={`/?cat=${bear.name.split("#")[1].trim()}`}
                className="flex flex-col h-full justify-between items-center"
              >
                <p className="text-3xl font-black mt-6 text-center">
                  <span>{bear.name}</span>
                </p>
                <div>
                  <img
                    placeholder="https://getuikit.com/docs/images/placeholder_600x400.svg"
                    className="mt-2 w-64 border-4 border-white"
                    src={bear.image}
                    alt={bear.name}
                  />
                  {/* <p className="text-xl font-black mt-2 text-center">
                    <span>
                      Rank: {bearToRanking[Number(bear[0].split("#")[1]) - 1]}
                    </span>
                  </p> */}
                </div>
              </a>
            </div>
          );
        })}
      </div>
      <div className="bg-gray-900 px-4 py-3 flex items-center justify-between border-t border-gray-800 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <button
            onClick={debounce(() => goToPreviousPage())}
            className="relative inline-flex items-center px-4 py-2 border border-gray-600 text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 cursor-pointer"
          >
            Previous
          </button>
          <button
            onClick={debounce(() => goToNextPage())}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-600 text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 cursor-pointer"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between w-full">
          <div className="w-full">
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px w-full justify-around"
              aria-label="Pagination"
            >
              <button
                onClick={debounce(() => goToPreviousPage())}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-600 bg-gray-900 text-sm font-medium text-indigo-500 hover:bg-gray-800 cursor-pointer"
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div>
                {pagesToRender.map((num) => (
                  <button
                    key={num}
                    onClick={debounce(() => changePage(num))}
                    aria-current="page"
                    className={`text-white relative inline-flex items-center px-4 py-2 text-sm font-medium cursor-pointer hover:bg-gray-800 ${
                      num === page ? "border-t-2 border-indigo-500  z-10" : ""
                    }`}
                  >
                    {num}
                  </button>
                ))}
              </div>
              <button
                onClick={debounce(() => goToNextPage())}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-600 bg-gray-900 text-sm font-medium text-indigo-500 hover:bg-gray-800 cursor-pointer"
              >
                <span className="sr-only">Next</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
